<template>
  <fieldset :disabled="disabled">
    <b-card no-body>
      <b-card-body>
        <b-form novalidate>
          <fieldset class="mb-3">
            <!-- <b-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="CPF" label-for="document">
                  <b-form-input
                    id="document"
                    v-model="form.document"
                    :state="validation.document"
                  />
                  <b-form-invalid-feedback v-if="$v.form.document.$invalid">
                    Informe um CPF válido
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row> -->

            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="validation.name"
                  />
                  <b-form-invalid-feedback v-if="$v.form.name.$invalid">
                    Informe o nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Usuário" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="validation.email"
                  />
                  <b-form-invalid-feedback v-if="$v.form.email.$invalid">
                    Informe um e-mail válido
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Senha" label-for="password">
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :state="validation.password"
                    type="password"
                  />
                  <b-form-invalid-feedback v-if="$v.form.password.$invalid">
                    Informe uma senha
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="Confirme a Senha"
                  label-for="password-confirmation"
                >
                  <b-form-input
                    id="password-password"
                    v-model="form.passwordConfirmation"
                    :state="validation.passwordConfirmation"
                    type="password"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.passwordConfirmation.$invalid"
                  >
                    <span
                      v-if="!$v.form.passwordConfirmation.required"
                      class="d-block"
                    >
                      Informe novamente a senha
                    </span>
                    <span
                      v-else-if="!$v.form.passwordConfirmation.matchesPassword"
                      class="d-block"
                    >
                      As senhas devem ser iguais
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <hr />
            <StoresFetcher>
              <template #default="{ data }">
                <fieldset>
                  <legend class="d-flex">
                    <span class="mr-3"> Lojas e Permissões </span>
                    <!-- <b-button
                      variant="info"
                      size="sm"
                      :disabled="isAddUserStoreDisabled(data)"
                      @click="handleAddUserStore"
                    >
                      <span class="fas fa-plus mr-1" /> Adicionar
                    </b-button> -->
                  </legend>

                  <div
                    v-for="(userStore, index) in form.userStores"
                    :key="userStore.storeId"
                  >
                    <UserStores
                      v-model="form.userStores[index]"
                      :stores="parseStoreOptions(data, form.userStores[index])"
                      :validation="$v.form.userStores"
                      :validate="$v.form.$dirty"
                      :enable-remove="index > 0"
                      @removeUserStore="handleRemoveUserStore"
                    />
                  </div>
                </fieldset>
              </template>
            </StoresFetcher>
            <hr />

            <StatusInput
              v-model="form.status"
              :validation="validation.status"
              :invalid="$v.form.status.$invalid"
            />
          </fieldset>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button variant="outline-secondary" @click="handleCancel('UsersIndex')">
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit"> Salvar </b-button>
    </div>
  </fieldset>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
// import { isValidCPF } from '@/helpers/validators'
import { ROLES } from '@/constants'
import withFormValidation from '@/mixins/withFormValidation'
import StatusInput from '@/components/StatusInput'
import StoresFetcher from '@/components/Stores/StoresFetcher'
import UserStores from '@/components/Users/UserStores'
import { isEmpty } from 'lodash/fp'

export default {
  name: 'UserForm',
  components: {
    StatusInput,
    StoresFetcher,
    UserStores
  },
  mixins: [
    withFormValidation({
      fields: [
        'userStores',
        // 'document',
        'name',
        'email',
        'password',
        'passwordConfirmation',
        'status'
      ]
    })
  ],
  validations: {
    form: {
      name: { required },
      // document: { isValidCPF },
      email: { required },
      password: {
        required: (value, { id }) => isEmpty(id)
      },
      passwordConfirmation: {
        required: (value, { id }) => isEmpty(id),
        matchesPassword: (value, { password }) => value === password
      },
      // role: { required },
      userStores: { required },
      status: { required }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    handleAddUserStore() {
      this.form.userStores = [
        ...this.form.userStores,
        {
          storeId: null,
          role: ROLES.EMPLOYER.value
        }
      ]
    },

    handleRemoveUserStore({ storeId }) {
      this.form.userStores = this.form.userStores.filter(
        (userStore) => userStore.storeId !== storeId
      )
    },

    isAddUserStoreDisabled(stores) {
      return this.form.userStores.length === stores.length
    },

    parseStoreOptions(stores, { storeId }) {
      const userStoresIds = this.form.userStores.map(({ storeId }) => storeId)

      return stores
        .filter(({ id }) => !userStoresIds.includes(id) || id === storeId)
        .map(({ id, name }) => ({
          code: id,
          label: name
        }))
    }
  }
}
</script>
